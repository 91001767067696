.comeFunziona {
  margin-top: 60px;
  margin-bottom: 60px;
}
.comeFunziona .common-title {
  margin-top: 0;
  text-align: center;
  margin-bottom: 60px;
}
.comeFunziona .step {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 60px;
  font-weight: bold;
  font-size: 1rem;
}
.comeFunziona .step svg {
  width: 90%;
  height: auto;
  max-width: 100px;
  margin-top: 1.5rem;
}